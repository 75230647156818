import { Background } from "./Background";

import styles from "./Sidequests.module.css";

const links = [
  {
    title: "Ordstjernen",
    description: "Gjett ordene med syv bokstaver",
    url: "https://www.vg.no/spill/ordstjernen",
    fill: "var(--accent)",
    image: "https://imbo.vgc.no/s/967yjxG",
  },
  {
    title: "Quiz",
    description: "Ukentlig quiz - syv nye hver fredag",
    url: "/quiz",
    fill: "var(--orange)",
    image: "https://imbo.vgc.no/s/WstUk5R",
  },
  {
    title: "Kryssord",
    description: "Finn synonymer og fyll ut feltene",
    url: "/kryssord",
    fill: "var(--pink)",
    image: "https://imbo.vgc.no/s/xKYxq3G",
  },
  {
    title: "Tegneserier",
    description: "Alle tegneseriene våre samlet",
    url: "https://www.vg.no/tegneserier",
    fill: "var(--teal)",
    image: "https://imbo.vgc.no/s/6Wdmqss",
  },
];

export const Sidequests = () => {
  return (
    <div className={styles.sidequests}>
      <ul>
        {links.map((link) => (
          <li key={link.url}>
            <a href={link.url}>
              <Background fill={link.fill}>
                <img src={link.image} alt={link.title} loading="lazy" />
              </Background>
              <div className={styles.text}>
                <h3 className="label-medium">{link.title}</h3>
                <p className="label-small">{link.description}</p>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
